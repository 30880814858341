import React, { useState, useEffect } from 'react';

import Menu from './menu/menu';
import CoursesPanel from './courses-panel';
import { FriendsSendInvitePopup } from './friends-invite/friends-send-invite-popup';
import { ShowInvite } from './friends-invite/show-invite';
import Group from './menu/groups';
import { Classes } from './menu/classes';
import { SkyboxPanel } from './skybox-panel';
import { Model3dPanel } from './3d-viewer-panel/Ui/3d-viewer-panel';
import SkechboardPanel from './sketchboards/skechboard-panel';
import SelectLanguage from './select-language/select-language';
import { LanguageProvider } from './Providers/LanguageContext';
import RoomLabel from './room-label/room-label';
import { LoadedModelsProvider } from './3d-viewer-panel/Services/LoadedModelsContext';
import TakeUsToPanelViewer from './take-us-to/panel-viewer';
import DemoPopup from './demo/demo';
import { container } from 'tsyringe';
import DemoService from '../DemoService';
import demo from './demo/demo';
import CornerLoader from './components/CornerLoader';

export default function App() {
    useEffect(() => {
        // @ts-ignore
        window.hideAllPanelFromMenuHtml = handleHideAllPanels;

        // @ts-ignore
        window.resetLessonSceneType = handleResetLessonSceneType;

        // @ts-ignore
        window.setLessonSceneType = (name) => handleSetLessonSceneType(name);
    }, []);

    const demoService = container.resolve(DemoService);

    const [showFriends, setShowFriends] = useState(false);
    const [hiddenGroups, setHiddenGroups] = useState(true);
    const [hiddenClasses, setHiddenClasses] = useState(true);
    const [showSkyboxPanel, setShowSkyboxPanel] = useState(false);
    const [showModel3dPanel, setShowModel3dPanel] = useState(false);
    const [showSketchboardPanel, setShowSketchboardPanel] = useState(false);
    const [indexBtnToUnselect, setIndexBtnToUnselect] = useState(null);
    const [lessonSceneType, setLessonSceneType] = useState(null);
    const [lessonRoomType, setLessonRoomType] = useState(null);

    const handleSetLessonSceneType = (name) => {
        handleLessonSceneType(true);
    };

    const handleResetLessonSceneType = () => {
        setLessonSceneType(null);
    };

    const handleHideAllPanels = () => {
        setShowFriends(false);
        setHiddenGroups(true);
        setHiddenClasses(true);
        setShowSkyboxPanel(false);
        setShowModel3dPanel(false);
        setShowSketchboardPanel(false);
    };

    const handleOpenFriendsInvitePopup = () => {
        if (demoService.isDemo) {
            demoService.sendRequestPopup();
            return;
        }
        handleHideAllPanels();
        setShowFriends(!showFriends);
    };

    const handleShowGroups = () => {
        setShowFriends(false);
        setHiddenGroups(!hiddenGroups);
        setHiddenClasses(true);
        setShowSkyboxPanel(false);
        setShowModel3dPanel(false);
        setShowSketchboardPanel(false);
    };

    const handleShowClasses = () => {
        setShowFriends(false);
        setHiddenGroups(true);
        setHiddenClasses(!hiddenClasses);
        setShowSkyboxPanel(false);
        setShowModel3dPanel(false);
        setShowSketchboardPanel(false);
    };

    const handlerOpenSkyboxPanel = () => {
        setShowFriends(false);
        setHiddenGroups(true);
        setHiddenClasses(true);
        setShowSkyboxPanel(!showSkyboxPanel);
        setShowModel3dPanel(false);
        setShowSketchboardPanel(false);
    };

    const handlerOpenModel3dPanel = () => {
        setShowFriends(false);
        setHiddenGroups(true);
        setHiddenClasses(true);
        setShowSkyboxPanel(false);
        setShowModel3dPanel(!showModel3dPanel);
        setShowSketchboardPanel(false);
    };

    const handleOpenSketchboardPanel = () => {
        setShowFriends(false);
        setHiddenGroups(true);
        setHiddenClasses(true);
        setShowSkyboxPanel(false);
        setShowModel3dPanel(false);
        setShowSketchboardPanel(!showSketchboardPanel);
    };

    const unselectBtn = (index) => {
        setIndexBtnToUnselect(index);
    };

    const handlerLessonGroup = (boolean) => {
        boolean ? setLessonRoomType('group') : setLessonRoomType('class');
    };

    const handleLessonSceneType = (isGroup) => {
        isGroup ? setLessonSceneType('group') : setLessonSceneType('class');
    };
    const [forceRender, setForceRender] = useState(0);

    const forceRerender = () => {
        console.log('test');
        setForceRender((prev) => prev + 1);
    };
    return (
        <LanguageProvider>
            <LoadedModelsProvider>
                <CornerLoader />
                <DemoPopup />
                <RoomLabel />
                <SelectLanguage
                    className="select-language"
                    forceRerender={forceRerender}
                />
                <ShowInvite />
                <CoursesPanel />
                <SkechboardPanel
                    show={showSketchboardPanel}
                    handleClose={handleOpenSketchboardPanel}
                />

                {showFriends && (
                    <FriendsSendInvitePopup
                        handleClose={handleOpenFriendsInvitePopup}
                        handleUnclick={(index) => {
                            unselectBtn(index);
                        }}
                    />
                )}

                <Model3dPanel
                    style={{
                        display: showModel3dPanel ? 'block' : 'none',
                    }}
                    handleClose={handlerOpenModel3dPanel}
                    handleUnclick={(index) => {
                        unselectBtn(index);
                    }}
                />

                {showSkyboxPanel && (
                    <TakeUsToPanelViewer
                        handleClose={handlerOpenSkyboxPanel}
                        handleUnclick={(index) => {
                            unselectBtn(index);
                        }}
                    />
                )}

                <div className="game-controlls">
                    <Menu
                        openShowFriends={handleOpenFriendsInvitePopup}
                        showClasses={handleShowClasses}
                        showGroups={handleShowGroups}
                        openSkyboxPanel={handlerOpenSkyboxPanel}
                        openModel3dPanel={handlerOpenModel3dPanel}
                        indexBtnToUnselect={indexBtnToUnselect}
                        lessonRoomType={lessonRoomType}
                        lessonSceneType={lessonSceneType}
                        openSketchboardPanel={handleOpenSketchboardPanel}
                    >
                        <Group
                            hidden={hiddenGroups}
                            clickLessonRoomType
                            lessonSceneType={handleLessonSceneType}
                        />
                        <Classes
                            hidden={hiddenClasses}
                            clickLessonRoomType
                            lessonSceneType={handleLessonSceneType}
                        />
                    </Menu>
                </div>
            </LoadedModelsProvider>
        </LanguageProvider>
    );
}

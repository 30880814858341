import VrObject3D from '../Three/VrObject3D';
import DoorService from './Door.service';
import Resources from '../../Resources';
import { autoInjectable } from 'tsyringe';
import {
    Box3,
    BoxGeometry,
    Color,
    DoubleSide,
    Material,
    Mesh,
    MeshBasicMaterial,
    MeshPhongMaterial,
    Object3D,
    Plane,
    Quaternion,
    Vector3,
} from 'three';
import { Block } from 'three-mesh-ui';
import IntersectionContainer from '../Controllers/IntersectionContainer';
import * as TWEEN from '@tweenjs/tween.js';
import { Text as TriokaText } from 'troika-three-text';
import GroupScene from '../../Scenes/Group/GroupScene';
import Camera from '../../Camera';
import Classroom from '../../Scenes/Classroom/ClassroomScene';
import TextGenerator from '../Text/TextGenerator';
import TranslationService from '../../Translations/TranslationService';

//@ts-ignore
import robot from '../../../../static/Roboto/Roboto-Regular.ttf';

const DOOR_OFFSET = 8;
const DOOR_WIDTH = 1.6;

@autoInjectable()
export default class DoorView extends VrObject3D {
    private doorObject: Object3D;
    private doorSpacing: number = 1;

    private currentGroup: number = 1;
    private allGroups: number = 0;

    private groupSize = 4;
    public localPlanes: Plane[] = [
        new Plane(new Vector3(1, 0, 0), 8),
        new Plane(new Vector3(-1, 0, 0), 2),
    ];
    private startingPositionX = 0.8;

    private doorsGroup: Object3D = new Object3D();

    private doorMaterial: Material;

    private doors: Object3D[] = [];

    public isGroup: boolean;

    public quantityIndex: number = 0;

    private intersectionBox: Box3 = new Box3();

    public constructor(
        options: { group: boolean; doorSpacing: number; localPlanes: Plane[] },
        public doorService?: DoorService,
        public resources?: Resources,
        public intersectionContainer?: IntersectionContainer,
        public camera?: Camera,
    ) {
        super();
        const { group, doorSpacing, localPlanes } = options;
        this.localPlanes = localPlanes;
        this.isGroup = group;
        this.doorMaterial = this.resources.items.lobbyScene.scene
            .getObjectByName('Cube006')
            .material.clone();

        this.doorMaterial.clippingPlanes = this.localPlanes;

        this.doorSpacing = doorSpacing;

        this.createView();

        this.doorObject =
            this.resources.items.lobbyScene.scene.getObjectByName('Cube003');
    }

    public setLocalPlanesToGlobal(position: Vector3, quaternion: Quaternion) {
        this.localPlanes.forEach((plane) => {
            const transformedNormal = plane.normal
                .clone()
                .applyQuaternion(quaternion);
            const transformedConstant =
                plane.constant - transformedNormal.dot(position);

            plane.normal.copy(transformedNormal);
            plane.constant = transformedConstant;
        });
    }

    public async createView() {
        const mesh = new Mesh(
            new BoxGeometry(DOOR_OFFSET - 0.4, 2, 1),
            new MeshBasicMaterial({ color: new Color('blue') }),
        );
        mesh.position.x = -2.4;

        mesh.visible = false;
        this.intersectionBox.setFromObject(mesh);
        mesh.geometry.computeBoundingBox();

        this.add(mesh);

        if (this.isGroup) {
            const groups = await this.doorService.getGroups();

            if (groups.data.length > 4) {
                this.createButtons();
            }

            groups.data.forEach((group, index) => {
                this.createDoor(group, index);

                if ((index + 1) % 4 === 0 || index === groups.data.length - 1) {
                    this.allGroups += 1;
                }
            });

            this.checkBoxIntersectable();

            this.add(this.doorsGroup);
            return;
        }

        const classesSorted = await this.doorService.getClassesSorted();

        if (classesSorted.length > 4) {
            this.createButtons();
        }

        classesSorted.forEach((schoolClass, index) => {
            this.createDoor(schoolClass, index);

            if ((index + 1) % 4 === 0 || index === classesSorted.length - 1) {
                this.allGroups += 1;
            }
        });
        this.checkBoxIntersectable();

        this.add(this.doorsGroup);
    }

    public createLabel() {
        const groupLabel = new TriokaText();
        groupLabel.text = this.isGroup ? 'Groups' : 'Classes';
        groupLabel.fontSize = 0.3;
        groupLabel.position.y = 2.2;
        groupLabel.position.x = -2.7;
        groupLabel.outlineWidth = 0.03;

        this.add(groupLabel);
    }

    public createButtons() {
        const arrowRightContent = new Block({
            width: 0.3,
            height: 0.3,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundTexture: this.resources.items.leftArrow,
        });
        arrowRightContent.position.x = -6.3;
        arrowRightContent.position.z = 0.2;
        arrowRightContent.position.y = 0;

        //@ts-ignore
        arrowRightContent.setupState({
            state: 'idle',
            attributes: {
                borderOpacity: 0,
            },
        });
        //@ts-ignore
        arrowRightContent.setupState({
            state: 'hovered',
            attributes: {
                borderOpacity: 0.5,
            },
        });
        arrowRightContent.userData.clickable = true;

        //@ts-ignore
        arrowRightContent.setupState({
            state: 'selected',
            attributes: {
                borderOpacity: 1,
            },
            onSet: () => {
                if (this.quantityIndex <= 4) {
                    return;
                }

                if (this.currentGroup === this.allGroups) {
                    return;
                }

                const actualPosition = this.doorsGroup.position;

                const maxWidth =
                    this.quantityIndex * this.doorSpacing * DOOR_WIDTH;

                if (actualPosition.x >= maxWidth) {
                    return;
                }

                new TWEEN.Tween(this.doorsGroup.position)
                    .to({
                        x: actualPosition.x + this.doorSpacing * 4,
                    })
                    .onStart(() => {
                        arrowRightContent.userData.clickable = false;
                    })
                    .onComplete((position) => {
                        arrowRightContent.userData.clickable = true;

                        this.doors.forEach((door: Mesh) => {
                            const box = door.userData.box as Box3;
                            box.translate(new Vector3(DOOR_OFFSET, 0, 0));
                        });

                        this.currentGroup += 1;

                        changeArrowsVisibility();

                        this.checkBoxIntersectable();
                    })
                    .duration(1000)
                    .start();
            },
        });

        const arrowLeftContent = new Block({
            width: 0.3,
            height: 0.3,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundTexture: this.resources.items.rightArrow,
        });

        arrowLeftContent.visible = false;

        //@ts-ignore
        arrowLeftContent.setupState({
            state: 'idle',
            attributes: {
                borderOpacity: 0,
            },
        });
        //@ts-ignore
        arrowLeftContent.setupState({
            state: 'hovered',
            attributes: {
                borderOpacity: 0.5,
            },
        });

        //@ts-ignore
        arrowLeftContent.setupState({
            state: 'selected',
            attributes: {
                borderOpacity: 1,
            },
            onSet: () => {
                if (this.quantityIndex <= 4) {
                    return;
                }
                const actualPosition = this.doorsGroup.position;

                if (this.currentGroup === 1) {
                    return;
                }

                new TWEEN.Tween(this.doorsGroup.position)
                    .to({
                        x: actualPosition.x - this.doorSpacing * 4,
                    })
                    .onStart(() => {
                        arrowRightContent.userData.clickable = false;
                    })
                    .onComplete((position) => {
                        arrowRightContent.userData.clickable = true;

                        this.doors.forEach((door: Mesh) => {
                            const box = door.userData.box as Box3;
                            box.translate(new Vector3(-DOOR_OFFSET, 0, 0));
                        });
                        this.checkBoxIntersectable();

                        this.currentGroup -= 1;

                        changeArrowsVisibility();
                    })
                    .duration(1000)
                    .start();
            },
        });

        const changeArrowsVisibility = () => {
            if (this.currentGroup === this.allGroups) {
                arrowRightContent.visible = false;
            } else {
                arrowRightContent.visible = true;
            }

            if (this.currentGroup === 1) {
                arrowLeftContent.visible = false;
            } else {
                arrowLeftContent.visible = true;
            }
        };

        arrowLeftContent.position.x = 1.8;
        arrowLeftContent.position.z = 0.2;
        arrowLeftContent.position.y = 0;

        this.intersectionContainer.addObjectToIntersect(arrowRightContent);
        this.intersectionContainer.addObjectToIntersect(arrowLeftContent);
        this.add(arrowRightContent);
        this.add(arrowLeftContent);
    }

    public checkBoxIntersectable() {
        this.doors.forEach((door: Mesh) => {
            const box = door.userData.box as Box3;

            const changeDoor = door
                .getObjectByName('changeRoomDoor')
                .userData.getDoor();

            changeDoor.userData.intersectable =
                this.intersectionBox.intersectsBox(box);
        });
    }

    public createDoor = (group, index) => {
        this.quantityIndex += 1;

        const doorGeometry = new BoxGeometry(DOOR_WIDTH, 2.4, 0.07);
        const doorCube = new Mesh(doorGeometry, this.doorMaterial);
        doorCube.position.set(this.startingPositionX, 0, 0);
        this.startingPositionX -= this.doorSpacing;

        const changeRoomDoor = this.doorService.setDoorTo(
            this.isGroup ? new GroupScene() : new Classroom(),
            this.isGroup ? group.id : group.subjectId,
            true,
            this.isGroup
                ? group.name
                : group.className + ' - ' + group.subjectName,
        );
        changeRoomDoor.name = 'changeRoomDoor';
        doorCube.add(changeRoomDoor);
        this.doors.push(doorCube);

        const textMaterial = new MeshPhongMaterial({
            color: 0x02e0f0,
            emissive: 0x440044,
            side: DoubleSide,
        });
        textMaterial.clippingPlanes = this.localPlanes;

        const doorName = new TriokaText();

        doorName.text = TranslationService.translate(
            this.isGroup
                ? group.name
                : group.className + ' - ' + group.subjectName,
        );
        doorName.fontSize = 0.2;
        doorName.color = 0x02e0f0;
        doorName.depthOffset = 0.1;
        doorName.material = textMaterial;
        doorName.position.y = 1.35;

        //offest
        doorName.position.x -= DOOR_WIDTH / 2;
        doorName.anchorX = 'left';
        doorName.anchorY = 'bottom';
        doorName.font = robot;
        doorName.maxWidth = DOOR_WIDTH;

        // const doorName = new TextGenerator(
        // this.isGroup
        //     ? group.name
        //     : 'group.className' + ' - ' + group.subjectName,
        // );
        // doorName.text = this.isGroup
        //     ? group.name
        //     : 'group.className' + ' - ' + group.subjectName;
        // doorName.material = textMaterial;
        // doorName.anchorX = 'center';
        // doorName.anchorY = 'bottom';
        //
        // doorName.fontSize = 0.2;
        // doorName.position.y = 1.2;
        // doorName.outlineWidth = 0.03;
        // doorName.maxWidth = DOOR_WIDTH;
        // doorName.depthOffset = 0.6;
        doorCube.add(doorName);

        const box = new Box3().setFromObject(doorCube);
        // const boxHelper = new Box3Helper(box, new Color('yellow'));
        doorCube.userData.box = box;

        // this.add(boxHelper);

        this.doorsGroup.add(doorCube);
    };

    public update() {}
}

import { EventDispatcher } from 'three';
import { autoInjectable, singleton } from 'tsyringe';

@singleton()
@autoInjectable()
export default class EventBus extends EventDispatcher {
    public sendLoadingStart() {
        this.dispatchEvent({ type: 'loadingStart' });
    }
    public sendLoadingEnd() {
        this.dispatchEvent({ type: 'loadingEnd' });
    }
}

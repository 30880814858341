export default {
  baseURL: 'https://dzwonek.pl/',
  janus: 'https://janus.lncdev.pl/janus',
  colyseus: 'wss://colyseus.lncdev.pl',
  colyseusHttp: 'https://colyseus.lncdev.pl',
      openAi: {
        assistantId: 'asst_8hxo3hLxcRlV6Ku2S1w5eOpr',
    },
    users: {
        edulabvr_student01: {
            username: 'edulabvr_student01',
            password: 's',
        },
        edulabvr_student02: {
                username: 'edulabvr_student02',
                password: 's'
        },
        edulabvr_teacher01: {
                username: 'edulabvr_teacher01',
                password: 't',
        }
       },
};

import { autoInjectable } from 'tsyringe';
import VrObject3D from '../Three/VrObject3D';
import ThreeMeshUI, { Block, Text } from 'three-mesh-ui';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import IntersectionContainer from '../Controllers/IntersectionContainer';
import { Color, DoubleSide, MeshPhongMaterial, TextureLoader } from 'three';
import Utils from '../../Utils/Utils';

import FontJSON from '@assets/fonts/Roboto-Regular-msdf.json';
import FontImage from '@assets/fonts/Roboto-Regular.png';

//icons
import IconScrollUpWhite from '../../../../static/assets/icons/icon-name-scroll-up-white-full.png';
import IconScrollDownWhite from '../../../../static/assets/icons/icon-name-scroll-down-white-full.png';
import IconAvatarWhite from '../../../../static/assets/icons/icon-name-avatar-white.png';
//@ts-ignore
import roboto from '../../../../static/Roboto/Roboto-Regular.ttf';
import { Text as TText } from 'troika-three-text';
import TranslationService from '../../Translations/TranslationService';
//@ts-ignore
@autoInjectable()
export class DashboardNewsSchool extends VrObject3D {
    public container: Block;
    public dashboardNewsSchool: any; //{}

    constructor(
        public httpClient?: AxiosHttpClient,
        public intersectionContainer?: IntersectionContainer,
    ) {
        super();

        this.fetchDashboardNewsSchool();

        this.setLabel();
    }

    public setLabel() {
        const screenSharingLabel = new TText();

        screenSharingLabel.text = TranslationService.translate(
            'vr.school_dash_board',
        );
        screenSharingLabel.fontSize = 0.2;
        screenSharingLabel.color = 0xc55f6c;
        screenSharingLabel.anchorX = 'left';
        screenSharingLabel.anchorY = 'middle';
        screenSharingLabel.depthOffset = 0.1;
        screenSharingLabel.material = new MeshPhongMaterial({
            color: new Color(0xc55f6c),
            emissive: new Color(0xc55f6c),
            emissiveIntensity: 1,
            side: DoubleSide,
        });
        screenSharingLabel.position.x = -2.4;
        screenSharingLabel.position.y = 1.5;
        screenSharingLabel.position.z = 0;

        screenSharingLabel.font = roboto;

        this.add(screenSharingLabel);
    }

    public setDefaultState(block: Block, click?: Function) {
        let selectedAttribute: any = {
            state: 'selected',
            attributes: {
                backgroundColor: new Color(0xffffff),
                backgroundOpacity: 0.1,
            },
        };

        if (click) {
            selectedAttribute = {
                state: 'selected',
                attributes: {
                    backgroundColor: new Color(0xffffff),
                    backgroundOpacity: 0.1,
                },
                onSet: () => {
                    click();
                },
            };
        }

        //@ts-ignore
        block.setupState({
            state: 'idle',
            attributes: {
                backgroundOpacity: 0.001,
            },
        });
        //@ts-ignore
        block.setupState({
            state: 'hovered',
            attributes: {
                backgroundColor: new Color(0xffffff),
                backgroundOpacity: 0.1,
            },
        });

        //@ts-ignore
        block.setupState(selectedAttribute);
    }

    setBlockHeight(textLines, textLineBase, textLineHeight) {
        return (textLineBase + textLineHeight) * textLines;
    }

    public async fetchDashboardNewsSchool() {
        this.dashboardNewsSchool =
            await this.httpClient.getDashboardSchoolNew();

        let borderWidth = 0.01;
        let borderColor = new Color(0xffffff);
        let borderOpacity = 0.8;
        let offsetActiveObj = 0.07;

        this.container = new Block({
            width: 4.6,
            height: 2.6,
            contentDirection: 'column',
            fontFamily: FontJSON,
            fontTexture: FontImage,
            fontColor: new Color(0xffffff),
            backgroundColor: new Color(0xffffff),
            backgroundOpacity: 0.001,
            borderRadius: 0,
            borderWidth: borderWidth,
            borderColor: borderColor,
            borderOpacity: 0,
            offset: 0.0001, //
            alignItems: 'center',
            justifyContent: 'center',
        });

        this.intersectionContainer.addObjectToIntersect(this.container, true);
        this.setDefaultState(this.container);

        // panel main
        const panelMainWrapper = new Block({
            width: 4.5,
            height: 2.5,
            hiddenOverflow: true,
            contentDirection: 'row',
            backgroundOpacity: 0,
            borderRadius: 0,
            borderWidth: 0,
            borderOpacity: 0,
            offset: 0.0002, //
        });

        //panel for content
        const panelMainContent = new Block({
            width: 4.3,
            height: 2.5,
            backgroundOpacity: 0,
            borderRadius: 0,
            borderWidth: 0,
            borderOpacity: 0,
            offset: 0.0002, //
        });

        const panelMainScrollWrapper = new Block({
            width: 0.2,
            height: 2.5,
            contentDirection: 'column',
            alignContent: 'right',
            backgroundOpacity: 0,
            borderRadius: 0,
            borderWidth: 0,
            borderOpacity: 0,
            offset: 0.0002, //
        });

        const panelBtnScrollOptions = {
            width: 0.1,
            height: 0.1,
            offset: 0.01,
            backgroundColor: new Color(0x4b4d4d),
            justifyContent: 'center',
            alignItems: 'center',
            backgroundOpacity: 0,
            borderRadius: 0,
            borderWidth: borderWidth,
            borderColor: borderColor,
            borderOpacity: borderOpacity,
        };

        const panelBtnScrollUp = new Block(panelBtnScrollOptions);
        const panelBtnScrollDown = new Block(panelBtnScrollOptions);

        const panelScrollSpace = new Block({
            width: 0.2,
            height: 2.3,
            backgroundOpacity: 0, //todo
            borderWidth: 0,
            borderOpacity: 0,
        });

        // IconScrollUpWhite
        new TextureLoader().load(IconScrollUpWhite, (texture) => {
            panelBtnScrollUp.add(
                new Block({
                    width: 0.08,
                    height: 0.08,
                    backgroundTexture: texture,
                    borderWidth: 0,
                }),
            );
        });
        // IconScrollDownWhite
        new TextureLoader().load(IconScrollDownWhite, (texture) => {
            panelBtnScrollDown.add(
                new Block({
                    width: 0.08,
                    height: 0.08,
                    backgroundTexture: texture,
                    borderWidth: 0,
                }),
            );
        });

        this.container.add(panelMainWrapper);
        panelMainWrapper.add(panelMainContent, panelMainScrollWrapper);
        panelMainScrollWrapper.add(
            panelBtnScrollUp,
            panelScrollSpace,
            panelBtnScrollDown,
        );

        this.setDefaultState(panelBtnScrollUp, () => {
            // @ts-ignore
            panelMainContent.position.y <= -panelMainContent.size.y ||
            panelMainContent.position.y <= 0
                ? (panelMainContent.position.y -= 0)
                : (panelMainContent.position.y -= 0.2);
        });
        this.setDefaultState(panelBtnScrollDown, () => {
            //@ts-ignore
            panelMainContent.position.y >= panelMainContent.size.y
                ? (panelMainContent.position.y += 0)
                : (panelMainContent.position.y += 0.2);
        });

        this.intersectionContainer.addObjectToIntersect(
            panelBtnScrollUp,
            false,
            true,
        );
        this.intersectionContainer.addObjectToIntersect(
            panelBtnScrollDown,
            false,
            true,
        );

        //----------------------------------------
        //data panel main ('panelMainContent')

        if (
            this.dashboardNewsSchool &&
            this.dashboardNewsSchool.hasOwnProperty('data') &&
            this.dashboardNewsSchool.data.length > 0
        ) {
            this.dashboardNewsSchool.data.sort((a, b) => {
                if (a.created_date < b.created_date) {
                    return 1;
                }
                if (a.created_date > b.created_date) {
                    return -1;
                }
                return 0;
            });

            this.dashboardNewsSchool.data.forEach((publication) => {
                // BLOCK FOR ONE NEWS
                const newsSchoolWrapper = new Block({
                    width: 4.3,
                    height: 0.65,

                    backgroundColor: new Color(0x454545),
                    backgroundOpacity: 0,
                    // backgroundOpacity: 1,
                    offset: 0.0015,
                    borderRadius: 0,
                    borderWidth: borderWidth,
                    borderColor: borderColor,
                    borderOpacity: borderOpacity,
                });

                const newsSchoolUserWrapperHeight = 0.2;

                const newsSchoolUserWrapper = new Block({
                    width: 4.3,
                    height: newsSchoolUserWrapperHeight,
                    backgroundOpacity: 0.1,
                    backgroundColor: new Color(0xffffff),
                    borderRadius: 0,
                    // borderWidth: 0,
                    // borderOpacity: 0,
                    borderWidth: 0.005,
                    borderOpacity: 1,
                    borderColor: borderColor,
                    contentDirection: 'row',
                    justifyContent: 'space-between', //todo a aaaaa
                    alignItems: 'center',
                    offset: 0.0001, //
                });

                const avatarLeftMargin = new Block({
                    width: 0.04,
                    height: 0.2,
                    borderWidth: 0,
                    borderOpacity: 0,
                    backgroundOpacity: 0,
                });

                const avatarRightMargin = new Block({
                    width: 0.04,
                    height: 0.2,
                    borderWidth: 0,
                    borderOpacity: 0,
                    backgroundOpacity: 0,
                });

                const mainNewsUserAvatar = new Block({
                    width: 0.2,
                    height: 0.2,
                    justifyItems: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundOpacity: 0, //todo
                    // backgroundOpacity: 1,
                    borderRadius: 0,
                    borderWidth: 0,
                    borderOpacity: 0,
                    offset: 0.0001, //
                });

                // avatar img
                new TextureLoader().load(IconAvatarWhite, (texture) => {
                    mainNewsUserAvatar.add(
                        new Block({
                            width: 0.12,
                            height: 0.12,
                            backgroundTexture: texture,
                            borderWidth: 0,
                        }),
                    );
                });
                // try {
                //     new TextureLoader().load(envierments.baseURL + publication.author.photo_url, (texture) => {
                //         mainNewsUserAvatar.add(
                //             new Block( {
                //                 width: 0.12,
                //                 height: 0.12,
                //                 backgroundTexture: texture,
                //                 borderWidth: 0,
                //             } )
                //         )
                //     });
                // } catch (error) {
                //     console.log(error)
                // }

                const mainNewsUserNameWrapper = new Block({
                    width: 4.0,
                    height: 0.2,
                    contentDirection: 'row',
                    justifyContent: 'space-between',
                    backgroundOpacity: 0,
                    borderRadius: 0,
                    borderWidth: 0,
                    borderOpacity: 0,
                    offset: 0.0001, //
                });

                const blockNameAndTimeOptions = {
                    width: 3.4,
                    height: 0.1,
                    // fontSize: 0.05,
                    fontFamily: FontJSON,
                    fontTexture: FontImage,
                    textAlign: 'left',
                    backgroundOpacity: 0,
                    justifyContent: 'center',
                    fontSize: 0.08,
                    borderRadius: 0,
                    borderWidth: 0,
                    borderOpacity: 0,
                    offset: 0.0001, //
                };

                const mainNewsUserName = new Block({
                    width: 2.7,
                    height: 0.2,
                    // fontSize: 0.05,
                    fontFamily: FontJSON,
                    fontTexture: FontImage,
                    textAlign: 'left',
                    backgroundOpacity: 0,
                    justifyContent: 'center',
                    fontSize: 0.085,
                    borderRadius: 0,
                    borderWidth: 0,
                    borderOpacity: 0,
                    offset: 0.0001,
                });

                const mainNewsTime = new Block({
                    width: 1.1,
                    height: 0.2,
                    fontFamily: FontJSON,
                    fontTexture: FontImage,
                    textAlign: 'right',
                    backgroundOpacity: 0,
                    justifyContent: 'center',
                    fontSize: 0.08,
                    borderRadius: 0,
                    borderWidth: 0,
                    borderOpacity: 0,
                    offset: 0.0001, //
                });
                const mainNewsUserNameSpace = new Block({
                    width: 0.1,
                    height: 0.2,
                    backgroundOpacity: 0,
                    borderWidth: 0,
                    borderOpacity: 0,
                });

                mainNewsUserName.add(
                    new Text({
                        content:
                            publication.author.first_name &&
                            publication.author.last_name
                                ? publication.author.first_name +
                                  ' ' +
                                  publication.author.last_name
                                : publication.author.username,
                        // fontColor: new Color(0xff00ff)
                    }),
                );
                mainNewsTime.add(
                    new Text({
                        content: Utils.formatPublicationDate(
                            publication.created_date,
                        ),
                        fontSize: 0.08,
                    }),
                );

                // block for text
                const newsSchoolContent = new Block({
                    width: 4.3,
                    height: 0.6, //todo w zależności od contentu
                    textAlign: 'left',
                    padding: 0.05,
                    backgroundOpacity: 0,
                    fontSize: 0.07,
                    borderRadius: 0,
                    borderWidth: 0,
                    borderOpacity: 0,
                    contentDirection: 'row',
                    fontFamily: FontJSON,
                    fontTexture: FontImage,
                });
                const regexPattern = /[^A-Za-z0-9]/g;

                newsSchoolContent.add(
                    new Block({
                        width: 0.04,
                        height: 0.6,
                        borderWidth: 0,
                        borderOpacity: 0,
                        backgroundOpacity: 0,
                        bestFit: 'shrink',
                    }),
                    new Text({
                        // content: publication.text
                        bestFit: 'auto',
                        content: Utils.removeHtmlTags(publication.text).replace(
                            regexPattern,
                            ' ',
                        ),
                    }),
                );

                ThreeMeshUI.update();

                //@ts-ignore
                let textLines = newsSchoolContent.lines.length;
                //@ts-ignore
                let textLineBase = newsSchoolContent.lines[0].lineBase;
                //@ts-ignore
                let textLineHeight = newsSchoolContent.lines[0].lineHeight;

                const space = new Block({
                    width: 4.2,
                    height: 0.02,
                    borderWidth: 0,
                    borderOpacity: 0,
                    backgroundOpacity: 0,
                });

                panelMainContent.add(newsSchoolWrapper, space);

                newsSchoolWrapper.add(newsSchoolUserWrapper, newsSchoolContent);
                newsSchoolUserWrapper.add(
                    avatarLeftMargin,
                    mainNewsUserAvatar,
                    avatarRightMargin,
                    mainNewsUserNameWrapper,
                );
                mainNewsUserNameWrapper.add(
                    mainNewsUserName,
                    mainNewsTime,
                    mainNewsUserNameSpace,
                );

                // @ts-ignore
                newsSchoolWrapper.set({
                    height:
                        this.setBlockHeight(
                            textLines,
                            textLineBase,
                            textLineHeight,
                        ) +
                        newsSchoolUserWrapperHeight +
                        0.04,
                });

                //@ts-ignore
                newsSchoolContent.set({
                    height: this.setBlockHeight(
                        textLines,
                        textLineBase,
                        textLineHeight,
                    ),
                });
            });
        } else {
            const newsSchoolInfoWrapper = new Block({
                width: 4.3,
                height: 0.65,
                backgroundOpacity: 0,
                offset: 0.0015,
                borderRadius: 0,
                borderWidth: borderWidth,
                borderColor: borderColor,
                borderOpacity: borderOpacity,
                fontFamily: FontJSON,
                fontTexture: FontImage,
                textAlign: 'left',
                fontSize: 0.08,
                padding: 0.05,
            });

            newsSchoolInfoWrapper.add(
                new Text({
                    content: TranslationService.translate('vr.no_messages'),
                }),
            );
            panelMainContent.add(newsSchoolInfoWrapper);
        }

        //.
        this.add(this.container);
    }
}

import { useEffect, useState } from 'react';
import { container } from 'tsyringe';
import { ListElement } from './list-element';
import DoorService from '../../Components/Doors/Door.service';
import TranslationService from '../../Translations/TranslationService';

export default function Group({
    hidden,
    clickLessonRoomType,
    lessonSceneType,
}) {
    const [groups, setGroups] = useState([]);
    const doorService = container.resolve(DoorService);

    useEffect(() => {
        const fetchGroups = async () => {
            const groups = await doorService.getGroups();
            if (groups.data) {
                setGroups(groups.data);
            }
        };

        fetchGroups();
    }, []);

    return (
        <div className={'menu-list ' + (hidden ? 'hidden' : '')}>
            {groups.map((group, key) => {
                return (
                    <ListElement
                        key={key}
                        roomId={group.id}
                        sceneName={group.name}
                        isGroup={true}
                        clickLessonRoomType={lessonSceneType}
                    >
                        {group.name}
                    </ListElement>
                );
            })}
            {groups.length === 0 ? (
                <div className={'not-assigned-menu'}>
                    <p className="text-center text-white pb-0 mb-0">
                        {TranslationService.translate('vr.no_assign_to_group')}
                    </p>
                </div>
            ) : (
                ''
            )}
        </div>
    );
}

import { ClipLoader } from 'react-spinners';
import React, { useEffect, useState } from 'react';
import TranslationService from '../../Translations/TranslationService';
import './main.css';
import { container } from 'tsyringe';
import EventBus from '../../Utils/EventBus';
export const CornerLoader = () => {
    const eventBus = container.resolve(EventBus);

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const handleLoadingStart = () => setVisible(true);
        const handleLoadingEnd = () => setVisible(false);

        eventBus.addEventListener('loadingStart', handleLoadingStart);
        eventBus.addEventListener('loadingEnd', handleLoadingEnd);

        return () => {
            eventBus.removeEventListener('loadingStart', handleLoadingStart);
            eventBus.removeEventListener('loadingEnd', handleLoadingEnd);
        };
    }, []);

    return (
        <>
            {visible && (
                <div className="corner-loader">
                    <span>
                        {TranslationService.translate('vr.corner_loading')}
                    </span>
                    <ClipLoader
                        color={'#FFF'}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        className="corner-spinner"
                    />
                </div>
            )}
        </>
    );
};

export default CornerLoader;

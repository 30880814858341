import React, { useState, useEffect } from 'react';
import { container } from 'tsyringe';
import ColyseusClient from '../../../Network/ColyseusClient';
import { useLoadedModelsContext } from '../Services/LoadedModelsContext';

const ModelControlButtons: React.FC = () => {
    const colyseusClient = container.resolve(ColyseusClient);
    const {
        loadedModels,
        handleChangeModel,
        totalSlots,
        currentModelIndex,
        setCurrentModelIndex,
    } = useLoadedModelsContext();

    const [rotationOn, setRotationOn] = useState(false);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        if (loadedModels.length > 0) {
            if (
                currentModelIndex === -1 ||
                currentModelIndex >= loadedModels.length
            ) {
                setCurrentModelIndex(0);
                handleChangeModel(loadedModels[0]);
            }
        } else {
            setCurrentModelIndex(-1);
        }
    }, [
        loadedModels,
        currentModelIndex,
        setCurrentModelIndex,
        handleChangeModel,
    ]);

    useEffect(() => {
        console.log(loadedModels);
    }, [loadedModels]);

    const handleClickHideModel = () => {
        colyseusClient.updateModel3dViewer({
            modelUrl: 'none',
            modelVisibility: true,
            isAnimationPlaying: false,
            isModelRotating: false,
            modelRotationDirection: 1,
            modelRotationSpeed: 0,
            modelScale: 0,
        });
    };

    const handleClickSetModelRotating = (isRotating: boolean) => {
        colyseusClient.updateModel3dViewer({ isModelRotating: isRotating });
        setRotationOn(isRotating);
    };

    const handleClickSetSpeedRotating = (speed: number) => {
        colyseusClient.updateModel3dViewer({ modelRotationSpeed: speed });
    };

    const handleClickSetZoom = (scale: number) => {
        colyseusClient.updateModel3dViewer({ modelScale: scale });
    };

    const handleNextModel = () => {
        const nextIndex = (currentModelIndex + 1) % loadedModels.length;
        setCurrentModelIndex(nextIndex);
        handleChangeModel(loadedModels[nextIndex]);
    };

    const handlePrevModel = () => {
        const prevIndex =
            (currentModelIndex - 1 + loadedModels.length) % loadedModels.length;
        setCurrentModelIndex(prevIndex);
        handleChangeModel(loadedModels[prevIndex]);
    };

    const handleCurrentModel = () => {
        if (loadedModels[currentModelIndex]) {
            handleChangeModel(loadedModels[currentModelIndex]);
        }
    };

    const handleVisibility = () => {
        colyseusClient.updateModel3dViewer({ modelVisibility: !isVisible });

        setIsVisible(!isVisible);
    };

    const ModelViewerNextPrevButtons: React.FC = () => {
        useEffect(() => {
            console.log('loaded-models', loadedModels);
        }, [loadedModels]);

        return (
            <>
                <button
                    className="additional-button"
                    onClick={handlePrevModel}
                    disabled={loadedModels.length <= 1}
                >
                    {'<'}
                </button>

                <button
                    className="additional-button current-model"
                    onClick={handleCurrentModel}
                >
                    {loadedModels[currentModelIndex]?.lessonName || 'No model'}
                </button>

                <button
                    className="additional-button"
                    onClick={handleNextModel}
                    disabled={loadedModels.length <= 1}
                >
                    {'>'}
                </button>
            </>
        );
    };

    return (
        <div className="manageModel3dPanel">
            <button
                className={`additional-button rotation-${
                    rotationOn ? 'off' : 'on'
                }`}
                style={{ color: rotationOn ? 'red' : undefined }}
                onClick={() => handleClickSetModelRotating(!rotationOn)}
                title={`Auto rotate ${rotationOn ? 'off' : 'on'}`}
            />

            <button
                className="additional-button rotation-speed-up"
                onClick={() => handleClickSetSpeedRotating(0.01)}
                title="Rotation speed up"
            />

            <button
                className="additional-button rotation-speed-down"
                onClick={() => handleClickSetSpeedRotating(-0.01)}
                title="Rotation speed down"
            />

            <button
                className="additional-button zoom-in"
                onClick={() => handleClickSetZoom(0.1)}
                title="Zoom in"
            />

            <button
                className="additional-button zoom-out"
                onClick={() => handleClickSetZoom(-0.1)}
                title="Zoom out"
            />

            <button
                className="additional-button on-off"
                title="on off"
                onClick={handleVisibility}
            >
                {isVisible ? 'On' : 'Off'}
            </button>

            <button
                className="additional-button btn-remove-model"
                onClick={handleClickHideModel}
                title="Remove model"
            />

            {loadedModels.length > 0 && <ModelViewerNextPrevButtons />}
        </div>
    );
};

export default ModelControlButtons;

import Resources from '../Resources';
import { container, singleton } from 'tsyringe';

@singleton()
export default class TranslationService {
    private lang = 'en';
    public constructor(public resources?: Resources) {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            this.lang = savedLanguage;
        }
    }

    public l(key: string, ...params: any[]): string {
        let translation = this.resources.items.translations.labels[key] || key;
        translation = translation.replace(/\\n/g, '\n');

        // if (this.getLanguage() === 'pl') {
        //     translation =
        //         this.resources.items.translationsPL.labels[key] || key;
        // }

        params.forEach((param, index) => {
            translation = translation.replace(`{${index}}`, param);
        });

        return translation;
    }

    public static translate(key: string, ...params: any[]): string {
        const service = container.resolve(TranslationService);
        return service.l(key, ...params);
    }

    public getLanguage(): string {
        return localStorage.getItem('selectedLanguage') || 'en';
    }

    public setLanguage(lang: string): void {
        this.lang = lang;
        localStorage.setItem('selectedLanguage', lang);
    }
}

import React, { useCallback, useEffect, useState } from 'react';
import { container } from 'tsyringe';
import ColyseusClient from '../../Network/ColyseusClient';
import EventBus from '../../Utils/EventBus';
import CourseView from '../3d-viewer-panel/Ui/3d-viewer-componetns';

const CourseDetails = ({ course }) => {
    const colyseusClient = container.resolve(ColyseusClient);
    const eventBus = container.resolve(EventBus);

    const [courseData, setCourseData] = useState(null);
    const [showDownloadSkyboxMask, setShowDownloadSkyboxMask] = useState(false);

    const processCourseData = useCallback((courseData) => {
        const chapterMap = new Map();
        const resourceMap = new Map();

        courseData.resources = courseData.resources.filter((resource) => {
            const tags = resource.tags
                ? resource.tags.split(',').map((tag) => tag.trim())
                : [];
            return tags.includes('3dmovie') || tags.includes('3dphoto');
        });

        courseData.resources.forEach((resource) => {
            if (!resourceMap.has(resource.parent_chapter)) {
                resourceMap.set(resource.parent_chapter, []);
            }
            resourceMap.get(resource.parent_chapter).push(resource);
        });

        courseData.chapters.forEach((chapter) => {
            chapter.lessons = resourceMap.get(chapter.id) || [];
            chapter.subChapters = [];
            chapterMap.set(chapter.id, chapter);
        });

        courseData.chapters = courseData.chapters.filter((chapter) => {
            if (chapter.parent_id === null) {
                return true;
            } else {
                const parentChapter = chapterMap.get(chapter.parent_id);
                if (parentChapter) {
                    parentChapter.subChapters.push(chapter);
                }
                return false;
            }
        });
        return courseData;
    }, []);

    useEffect(() => {
        if (course) {
            const storedCourseData = localStorage.getItem(
                `courseData_${course.id}`,
            );
            if (storedCourseData) {
                setCourseData(JSON.parse(storedCourseData));
            } else {
                const processedCourse = processCourseData(course);
                setCourseData(processedCourse);
                localStorage.setItem(
                    `courseData_${course.id}`,
                    JSON.stringify(processedCourse),
                );
            }
        }
    }, [course, processCourseData]);

    const shouldShowSkybox = () => {
        return true;
    };

    const isSkyboxLoaded = (skyboxId) => {
        return false;
    };

    const handleSelectSkybox = (e, url, id, title, icon) => {};
    const extractIdFromUrl = (url) => {
        const parts = url.split('/');
        return parts[parts.length - 1];
    };
    const handleLoadSkybox = (url, id, title, icon) => {
        const fileId = extractIdFromUrl(url);
        colyseusClient.changeSkybox(fileId);
    };

    return (
        <div className="course-details">
            <CourseView
                type="skybox"
                courseData={courseData}
                shouldShowLesson={shouldShowSkybox}
                isModelLoaded={isSkyboxLoaded}
                handleSelectModel={handleSelectSkybox}
                handleLoadModel={handleLoadSkybox}
            />
        </div>
    );
};

export default CourseDetails;

import Time from '../../Utils/Time';
import { autoInjectable } from 'tsyringe';
import VrScene, { Rooms } from '../../Components/Three/VrScene';
import {
    Color,
    DoubleSide,
    MathUtils,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    PlaneGeometry,
    Vector3,
    VideoTexture,
    sRGBEncoding,
    MeshPhongMaterial,
} from 'three';
import Resources from '../../Resources';
import ColyseusClient from '../../Network/ColyseusClient';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import LobbyScene from '../LobbyScene/LobbyScene';
import { LessonView } from '../../Components/Ui/MainView';
import { MyContent } from '../../Components/Ui/MyContent';
import { Calendar } from '../../Components/Ui/Calendar';
import { Utils } from '../../Utils/Utils';
import { NotificationsBoard } from '../../Components/Ui/NotificationsBoard';
import ChatBot from '../../Components/ChatBot';
import { AXIS, ZoomToView } from '../../Components/ZoomTo/ZoomTo.view';
import TextGenerator from '../../Components/Text/TextGenerator';
import ChatbotButtons from '../../react/vr-chatbot/chatbot-buttons';

import { Text as TText } from 'troika-three-text';

//@ts-ignore
import roboto from '../../../../static/Roboto/Roboto-Regular.ttf';
import TranslationService from '../../Translations/TranslationService';
import Model3dViewer from '../../Components/Model3dViewer';

const userDataNormal: { [wall: string]: Vector3 } = {
    wallA: new Vector3(-1, 0, 0),
    wallB: new Vector3(1, 0, -1),
    wallC: new Vector3(1, 0, 1),
    wallD: new Vector3(0, 0, 1),
};

declare global {
    interface Window {
        chatbot: ChatBot;
    }
}

@autoInjectable()
export default class PrivateRoomScene extends VrScene {
    public name = 'PrivateRoom';

    public worldModel: Object3D;

    public videoElement: HTMLVideoElement;

    public projector: Mesh;

    public chatBot: ChatBot;

    public screenSharing = true;

    public constructor(
        public resources?: Resources,
        public colyseusClient?: ColyseusClient,
        public httpClient?: AxiosHttpClient,
        public modelViewer?: Model3dViewer,
    ) {
        super();

        this.sceneNameForLabel = TranslationService.translate(
            'vr.study_room_label',
        );

        this.setWorldModel();
        this.setFloor();

        const geometry = new PlaneGeometry(4, 2.25, 1);
        const material = new MeshBasicMaterial({
            color: 0xffffff,
            side: DoubleSide,
        });
        this.projector = new Mesh(geometry, material);
        this.add(this.projector);
        this.worldModel
            .getObjectByName('Plane011')
            .getWorldPosition(this.projector.position);
        this.projector.translateZ(0.1);

        const roomLabel = new TextGenerator(
            TranslationService.translate('vr.study_room_label'),
        );
        roomLabel.position.set(5, -1, -4.7);
        roomLabel.rotateY(-MathUtils.DEG2RAD * 90);
        this.add(roomLabel);

        this.worldModel.getObjectByName('Text003').visible = false;
        this.worldModel.getObjectByName('Text002').visible = false;
        this.worldModel.getObjectByName('Text').visible = false;
        this.worldModel.getObjectByName('Text001').visible = false;
        this.worldModel.getObjectByName('Text004').visible = false;

        this.setLabel();

        // if (window['app'].debug) {
        //     const gui = window['app'].debug;
        //
        //     const folder = gui.addFolder('napis');
        //
        //     const myObject = {
        //         myString: 'lil-gui',
        //         x: roomLabel.position.x,
        //         y: roomLabel.position.y,
        //         z: roomLabel.position.z,
        //         color: '#' + new Color('blue').getHexString(),
        //         emisive: '#' + new Color('blue').getHexString(),
        //         opacity: 1,
        //         size: 1,
        //         depth: 1,
        //     };
        //
        //     folder.add(myObject, 'x', -10, 10).onChange((value) => {
        //         roomLabel.position.setX(value);
        //         this.updateMatrix();
        //     });
        //     folder.add(myObject, 'y', -10, 10).onChange((value) => {
        //         roomLabel.position.setY(value);
        //     });
        //     folder.add(myObject, 'z', -10, 10).onChange((value) => {
        //         roomLabel.position.setZ(value);
        //     });
        //     folder.add(myObject, 'color').onChange((value) => {
        //         //@ts-ignore
        //         roomLabel.material.color = new Color(value);
        //     });
        //     folder.add(myObject, 'emisive', 255).onChange((value) => {
        //         //@ts-ignore
        //         roomLabel.material.emissive = new Color(value);
        //     });
        //     folder.add(myObject, 'opacity', 0, 1).onChange((value) => {
        //         roomLabel.material.opacity = value;
        //     });
        //     folder.add(myObject, 'size', -1, 5).onChange((value) => {
        //         //@ts-ignore
        //         roomLabel.scale.setX(value);
        //         roomLabel.scale.setY(value);
        //     });
        //     folder.add(myObject, 'depth', -10, 10).onChange((value) => {
        //         roomLabel.scale.setZ(value);
        //     });
        // }
    }

    public setLabel() {
        const mainLobbyLabel = new TText();

        mainLobbyLabel.text =
            TranslationService.translate('vr.menu_main_lobby');
        mainLobbyLabel.fontSize = 0.2;
        mainLobbyLabel.color = 0xffff00;
        mainLobbyLabel.anchorX = 'center';
        mainLobbyLabel.anchorY = 'middle';
        mainLobbyLabel.depthOffset = 0.1;
        mainLobbyLabel.material = new MeshPhongMaterial({
            color: new Color(0xffff00),
            emissive: new Color(0x444400),
            emissiveIntensity: 1,
            side: DoubleSide,
        });
        mainLobbyLabel.position.x = 5;
        mainLobbyLabel.position.y = 0;
        mainLobbyLabel.position.z = -0.6;
        mainLobbyLabel.rotation.y = -Math.PI / 2;

        mainLobbyLabel.font = roboto;

        const screenSharingLabel = new TText();

        screenSharingLabel.text =
            TranslationService.translate('vr.screen_sharing');
        screenSharingLabel.fontSize = 0.2;
        screenSharingLabel.color = 0x772385;
        screenSharingLabel.anchorX = 'center';
        screenSharingLabel.anchorY = 'middle';
        screenSharingLabel.depthOffset = 0.1;
        screenSharingLabel.material = new MeshPhongMaterial({
            color: new Color(0x772385),
            emissive: new Color(0x772385),
            emissiveIntensity: 1,
            side: DoubleSide,
        });
        screenSharingLabel.position.x = -0.2;
        screenSharingLabel.position.y = 0.6;
        screenSharingLabel.position.z = -5.1;

        screenSharingLabel.font = roboto;

        this.add(screenSharingLabel);
        this.add(mainLobbyLabel);
    }

    public setWorldModel() {
        this.worldModel = this.resources.items.privateRoom.scene.clone();

        this.worldModel.traverse((child) => {
            child.castShadow = false;
            child.receiveShadow = false;
        });

        this.worldModel.position.y = -2.65;
        this.add(this.worldModel);
    }

    public setFloor() {
        const floorGroup = new Object3D();

        floorGroup.name = 'floorGroup';

        const mesh = new Mesh(
            new PlaneGeometry(10, 10, 32),
            new MeshBasicMaterial({ color: new Color('pink') }),
        );

        mesh.name = 'floor';
        mesh.visible = false;
        mesh.position.y = -2.3;
        mesh.quaternion.setFromAxisAngle(new Vector3(1, 0, 0), -Math.PI / 2);

        floorGroup.add(mesh);

        this.addBoundingBox(mesh, false);

        this.add(floorGroup);
    }

    public start() {
        this.chatBot = new ChatBot();
        window.chatbot = this.chatBot;
        this.chatBot.rotateY(MathUtils.degToRad(45));
        this.chatBot.position.set(-4.5, -0.5, -4.5);
        this.add(this.chatBot);

        this.setTpRings();

        Utils.waitForElm(`#video-local`).then((elem: HTMLVideoElement) => {
            this.videoElement = elem;

            const videoTexture = new VideoTexture(elem);
            videoTexture.encoding = sRGBEncoding;

            //@ts-ignore
            this.projector.material = new MeshBasicMaterial({
                map: videoTexture,
            });

            //@ts-ignore
            this.projector.material.needsUpdate = true;
        });

        const zoomToProjector = new ZoomToView({
            target: this.projector.position,
            axis: AXIS.Z,
            offset: 2.5,
        });

        const projectorZoomToPosition = this.projector.position.clone();
        projectorZoomToPosition.y += 1.2;
        projectorZoomToPosition.x += 2.6;
        zoomToProjector.position.copy(projectorZoomToPosition);

        this.add(zoomToProjector);

        const lessonView = new MyContent();
        lessonView.scale.set(1.1, 1, 1.1);
        lessonView.translateY(-0.1);

        const myContentPanel = this.worldModel.getObjectByName('blank_not001');

        myContentPanel.getWorldPosition(lessonView.position);
        myContentPanel.scale.add(new Vector3(2, 0, 0.02));
        myContentPanel.translateX(2);

        // this.worldModel.getObjectByName('Plane017').scale.set(1.2, 1.2, 1.2)
        // this.worldModel.getObjectByName('blank_not001').scale.set(1.1, 1, 1.05);
        // this.worldModel.getObjectByName('blank_not001').translateX(0.1);

        lessonView.rotateY(Math.PI / 2);
        lessonView.translateY(-0.1);
        this.add(lessonView);

        const zoomToLessonView = new ZoomToView({
            target: lessonView.position,
            axis: AXIS.X,
            offset: 2.5,
        });

        zoomToLessonView.rotateY(MathUtils.DEG2RAD * 90);

        const lessonViewZoomToPosition = lessonView.position.clone();
        lessonViewZoomToPosition.y += 1.2;
        lessonViewZoomToPosition.z -= 2.7;
        zoomToLessonView.position.copy(lessonViewZoomToPosition);

        this.add(zoomToLessonView);

        // ----

        const calendarView = new Calendar();
        calendarView.scale.set(0.8, 0.8, 0.8);
        calendarView.rotateY(MathUtils.DEG2RAD * 180);
        this.worldModel
            .getObjectByName('Plane015')
            .getWorldPosition(calendarView.position);

        this.add(calendarView);

        const zoomToCalendar = new ZoomToView({
            target: calendarView.position,
            axis: AXIS.Z,
            offset: -2,
        });

        zoomToCalendar.rotateY(MathUtils.DEG2RAD * 180);

        const calendarZoomToPosition = calendarView.position.clone();
        calendarZoomToPosition.y += 1;
        calendarZoomToPosition.x -= 0.8;
        zoomToCalendar.position.copy(calendarZoomToPosition);

        this.add(zoomToCalendar);

        const notificationBoard = new NotificationsBoard();
        notificationBoard.scale.set(1.1, 1, 1.1);
        notificationBoard.rotateY(MathUtils.DEG2RAD * 180);
        this.worldModel
            .getObjectByName('blank_not007')
            .getWorldPosition(notificationBoard.position);
        this.add(notificationBoard);

        const zoomToNotification = new ZoomToView({
            target: notificationBoard.position,
            axis: AXIS.Z,
            offset: -2.5,
        });

        zoomToNotification.rotateY(MathUtils.DEG2RAD * 180);

        const notificationZoomToPosition = notificationBoard.position.clone();
        notificationZoomToPosition.y += 1.22;
        notificationZoomToPosition.x -= 2.6;
        notificationZoomToPosition.z -= 0.03;

        zoomToNotification.position.copy(notificationZoomToPosition);

        this.add(zoomToNotification);

        const doorToLobby = this.setDoorTo(
            new LobbyScene(),
            this.resources.items.user.id + Rooms.Lobby,
            true,
            'Lobby',
        );
        this.worldModel
            .getObjectByName('Cube009')
            .getWorldPosition(doorToLobby.position);
        doorToLobby.rotateY(-(MathUtils.DEG2RAD * 90));
        this.add(doorToLobby);

        // this.modelViewer.position.set(7.326, 0, -6.5);
        this.modelViewer.position.set(0, -1, 0);
        // this.modelViewer.scale.set(2, 2, 2);
        this.add(this.modelViewer);
    }

    public update(time: Time): void {
        if (
            this.videoElement &&
            this.videoElement.classList.contains('transmiting')
        ) {
            this.projector.visible = true;
        } else if (this.videoElement) {
            this.projector.visible = false;
        }
    }

    public onLeave(): void {
        this.chatBot.destroy();
    }
}

import { EventDispatcher } from 'three';
import { autoInjectable, singleton } from 'tsyringe';
import Resources from '../Resources';

interface SchoolToolsStatus {
    blog: boolean;
    calendar: boolean;
    forum: boolean;
    news: boolean;
}

@singleton()
export default class UserService extends EventDispatcher {
    public admins_usernames: string[] = [];
    public age_type: number = 0;
    public birth_date: string | null = null;
    public card_id: string | null = null;
    public card_started_token: string | null = null;
    public chat_token: string = '';
    public email: string = '';
    public email_adult: string | null = null;
    public firestore_token: string = '';
    public first_name: string = '';
    public first_name_adult: string | null = null;
    public gender: number = 0;
    public id: number = 0;
    public inactivity_limit: number | null = null;
    public is_connected_to_minstructor: boolean = false;
    public is_district_admin: boolean = false;
    public is_during_import: boolean = false;
    public is_publisher_admin: boolean = false;
    public is_publisher_admin_id: number | null = null;
    public is_school_admin: boolean = false;
    public is_school_member: boolean = false;
    public is_school_student: boolean = false;
    public is_school_teacher: boolean = false;
    public is_staff: boolean = false;
    public is_super_admin: boolean = false;
    public is_superuser: boolean = false;
    public lang_id: number = 0;
    public last_name: string = '';
    public last_name_adult: string | null = null;
    public mycontent_list_view: any | null = null;
    public mycontent_toc_list_view: any | null = null;
    public mycontent_view_only_mode: any | null = null;
    public photo_url: string = '';
    public profile_type: number = 0;
    public profile_type_label: string = '';
    public question_bank_courses_lang: string = '';
    public regulation_agreement: boolean = false;
    public regulation_agreement_date: string = '';
    public regulation_first_modification: boolean = false;
    public regulation_information: boolean = false;
    public regulation_marketing: boolean = false;
    public school_icon_url: string | null = null;
    public school_id: number | null = null;
    public school_is_active: boolean | null = null;
    public school_name: string | null = null;
    public school_tools_status: SchoolToolsStatus = {
        blog: false,
        calendar: false,
        forum: false,
        news: false,
    };
    public time_zone_auto_detecting: boolean = true;
    public usage_type: number = 0;
    public user_creation_type: number = 0;
    public username: string = '';

    public constructor(public resources?: Resources) {
        super();
        this.mapUser(this.resources.items.user);
    }

    public init() {
        if (this.resources?.items.user) {
        } else {
            console.error('User data not available in resources');
        }
    }

    private mapUser(userData: any) {
        Object.keys(userData).forEach((key) => {
            if (this.hasOwnProperty(key)) {
                (this as any)[key] = userData[key];
            }
        });
    }
}

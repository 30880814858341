import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js';

import EventEmitter from './EventEmitter';
import { AudioLoader, ObjectLoader, TextureLoader } from 'three';
import HttpLoader from '../Components/HttpLoader';
import AxiosHttpClient from '../Network/AxiosHttpClient';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';

export default class Loader extends EventEmitter {
    public toLoad: number;
    public loaded: number;
    public loaders: { extensions: string[]; action: (resource: any) => void }[];
    public items: { [key: string]: any } = {};

    public constructor(public httpClient: AxiosHttpClient) {
        super();

        this.setLoaders();

        this.toLoad = 0; // total resources
        this.loaded = 0; // loaded resources
    }

    public setLoaders() {
        const textureLoader = new TextureLoader();
        this.loaders = [];

        // ex: _resource: { name: 'matcapRed', source: 'xxx.jpg' }
        this.loaders.push({
            extensions: ['jpg', 'png', 'jpeg'],
            action: (_resource) => {
                textureLoader.load(_resource.source, (_texture) => {
                    this.fileLoadEnd(_resource, _texture);
                });
            },
        });

        //Audio
        const audioLoader = new AudioLoader();
        this.loaders.push({
            extensions: ['mp3', 'ogg'],
            action: (_resource) => {
                audioLoader.load(_resource.source, (_audio) => {
                    this.fileLoadEnd(_resource, _audio);
                });
            },
        });

        // Draco
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/draco/');
        dracoLoader.setDecoderConfig({ type: 'js' });

        this.loaders.push({
            extensions: ['drc'],
            action: (_resource) => {
                dracoLoader.load(_resource.source, (_data) => {
                    this.fileLoadEnd(_resource, _data);
                    // this is deprecated https://discourse.threejs.org/t/issue-with-setdecoderpath/9556
                    (DRACOLoader as any).releaseDecoderModule();
                });
            },
        });

        const svgLoader = new SVGLoader();
        this.loaders.push({
            extensions: ['svg'],
            action: (_resource) => {
                svgLoader.load(_resource.source, (_data) => {
                    this.fileLoadEnd(_resource, _data);
                });
            },
        });

        // GLTF
        const gltfLoader = new GLTFLoader();
        gltfLoader.setDRACOLoader(dracoLoader);

        this.loaders.push({
            extensions: ['glb', 'gltf'],
            action: (_resource) => {
                gltfLoader.load(_resource.source, (_data) => {
                    this.fileLoadEnd(_resource, _data);
                });
            },
        });

        // FBX
        const fbxLoader = new FBXLoader();

        this.loaders.push({
            extensions: ['fbx'],
            action: (_resource) => {
                fbxLoader.load(_resource.source, (_data) => {
                    this.fileLoadEnd(_resource, _data);
                });
            },
        });

        const objectLoader = new ObjectLoader();
        const fontLoader = new FontLoader();

        // this.loaders.push({
        //     extensions: ['json'],
        //     action: (_resource) => {
        //         objectLoader
        //     }
        // })

        this.loaders.push({
            extensions: ['json'],
            action: (_resource) => {
                if (_resource.type === 'font') {
                    fontLoader.load(_resource.source, (_data) => {
                        this.fileLoadEnd(_resource, _data);
                    });
                } else {
                    objectLoader.load(_resource.source, (_data) => {
                        this.fileLoadEnd(_resource, _data);
                    });
                }
            },
        });
    }

    // execute after loading each asset
    public fileLoadEnd(_resource: any, _data: any) {
        this.loaded += 1;
        this.items[_resource.name] = _data;

        this.trigger('fileEnd', [_resource, _data]);

        if (this.loaded === this.toLoad) {
            this.trigger('end');
        }
    }

    private loadSingleResource(_resource: any) {
        if (_resource.type === 'http') {
            if (_resource.dependsOn) {
                const dependency = this.items[_resource.dependsOn.resource];
                if (dependency) {
                    const resolvedSource = _resource.source(dependency);
                    const httpLoader = new HttpLoader(this.httpClient);
                    httpLoader.load(resolvedSource, (data) => {
                        this.fileLoadEnd(_resource, data);
                    });
                    this.toLoad += 1;
                }
            } else {
                const httpLoader = new HttpLoader(this.httpClient);
                httpLoader.load(_resource.source, (data) => {
                    this.fileLoadEnd(_resource, data);
                });
                this.toLoad += 1;
            }
        } else {
            const extensionMatch = _resource.source.match(/\.([a-z]+)$/);

            if (typeof extensionMatch?.[1] !== 'undefined') {
                const extension = extensionMatch[1];
                const loader = this.loaders.find((_loader) =>
                    _loader.extensions.find(
                        (_extension) => _extension === extension,
                    ),
                );

                if (loader) {
                    if (_resource.dependsOn) {
                        const dependency =
                            this.items[_resource.dependsOn.resource];
                        if (dependency) {
                            const resolvedSource =
                                typeof _resource.source === 'function'
                                    ? _resource.source(dependency)
                                    : _resource.source;

                            loader.action({
                                ..._resource,
                                source: resolvedSource,
                            });
                            this.toLoad += 1;
                        }
                    } else {
                        loader.action(_resource);
                        this.toLoad += 1;
                    }
                } else {
                    console.warn(`Cannot found loader for ${_resource}`);
                }
            } else {
                if (_resource.type === 'font' || _resource.type === 'cube') {
                    const loader = this.loaders.find((_loader) =>
                        _loader.extensions.find(
                            (_extension) => _extension === 'json',
                        ),
                    );

                    if (loader) {
                        if (_resource.dependsOn) {
                            const dependency =
                                this.items[_resource.dependsOn.resource];
                            if (dependency) {
                                const resolvedSource =
                                    typeof _resource.source === 'function'
                                        ? _resource.source(dependency)
                                        : _resource.source;

                                loader.action({
                                    ..._resource,
                                    source: resolvedSource,
                                });
                                this.toLoad += 1;
                            }
                        } else {
                            loader.action(_resource);
                            this.toLoad += 1;
                        }
                    }
                } else {
                    console.warn(`Cannot found extension of ${_resource}`);
                }
            }
        }
    }

    public load(_resources: any[] = []) {
        const independentResources = _resources.filter((r) => !r.dependsOn);
        const dependentResources = _resources.filter((r) => r.dependsOn);

        independentResources.forEach((_resource) => {
            this.loadSingleResource(_resource);
        });

        this.on('fileEnd', (_resource: any) => {
            dependentResources.forEach((depResource) => {
                if (depResource.dependsOn.resource === _resource.name) {
                    this.loadSingleResource(depResource);
                }
            });
        });
    }
}
